import SlotData from './SlotData.js';

export default class SlotDatas {
  constructor (options) {
    this.datas = [];
    const mySlotData = this;
    if (options) {
      if (options.datas && options.datas.length) {
        options.datas.forEach((item) => {
          mySlotData.datas.push(new SlotData(item));
        });
      }
    }
  }
  addData (config) {
    if (this.getDataById(config.id)) {
      console.warn(`Slot config id ${config.id} already exists`);
      return;
    }
    this.datas.push(new SlotData(config));
  }
  getDataById (id) {
    let resultSlotData = null;
    this.datas.every((item) => {
      if (typeof item.id === 'undefined') return true;

      if (item.id === id) {
        resultSlotData = item;
        return false;
      }
      return true;
    });
    return resultSlotData;
  }
}