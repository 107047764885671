/* Preset options Example ------------------------------------------------------
{
  type: "display",
  id: "Billboard",
  adUnit: "/1006421/dev_leaderboard_1",
  sizes: [[970, 250], [970,90], [728,90], [320, 100], [320, 50], [300, 100], [300, 50]],
  sizeMap: [
    { minSize: [998, 0], sizes: [[970, 250], [970,90], [728, 90]] },
    { minSize: [768, 0], sizes: [[728, 90]] },
    { minSize: [0, 0], sizes: [[320, 100], [320, 50], [300, 100], [300, 50]] },
  ],
  targeting: [
    { key: "placement",  value: ["ATF"] },
    { key: "section",  value: ["News"]}
  ],
}
------------------------------------------------------------------------------*/

export default class SlotData {
  constructor (options) {

    /* Possible values: --------------------------------------------------------
      display | outofpage
    --------------------------------------------------------------------------*/
    this.type = options.type;

    /* Name to use for lookup and to generate ad-slot-div-id attribute        */
    this.id = options.id;

    /* Ad Unit reference for ad slot. example: ---------------------------------
      '/1006421/dev_leaderboard_1'
    --------------------------------------------------------------------------*/
    this.adUnit = options.adUnit;

    /* example: ----------------------------------------------------------------
      [[970, 300], [970, 90], [728,90], [320, 100], [320, 50], [300, 50]]
    --------------------------------------------------------------------------*/
    this.sizes = options.sizes;

    /* example: ----------------------------------------------------------------
      {
        { minSize: [980, 0], sizes: [[970, 300], [970, 90], [728, 90]] },
        { minSize: [728, 0], sizes: [728, 90] },
        { minSize: [0, 0], sizes: [[320, 50], [300, 50]] },
      }
    --------------------------------------------------------------------------*/
    this.sizeMap = options.sizeMap;

    /* Ad slot specific targeting. example: ------------------------------------
      [
        { key: "placement",  value: ["ATF"] },
        { key: "section",  value: ["News"]}
      ]
    --------------------------------------------------------------------------*/
    this.targeting = options.targeting; 

    /* Ad slot specific collapseEmptyDiv. example: -----------------------------
      collapseEmptyDiv: true,
      omit for the default
    --------------------------------------------------------------------------*/
    this.collapseEmptyDiv = options.collapseEmptyDiv; 
  }
}
