export default { error, info, log, success, warn, enable, disable, isEnabled }

let enabled = true;
const cornsilkb = 'color:darkgrey;background:cornsilk;padding:4px;border-radius:4px;';
const grayb = 'color:white;background:darkgray;padding:4px;border-radius:4px;';
const greenb = 'color:white;background:limegreen;padding:4px;border-radius:4px;';
const orangeb = 'color:white;background:darkorange;padding:4px;border-radius:4px;';
const redb = 'color:white;background:indianred;padding:4px;border-radius:4px;';

function error () {
  if(enabled === true) {
    window.console.error('%cFDN GPT', redb, ...arguments);
  }
}

function info () {
  if(enabled === true) {
    window.console.info('%cFDN GPT', cornsilkb, ...arguments);
  }
}

function log () {
  if(enabled === true) {
    window.console.log('%cFDN GPT', grayb, ...arguments);
  }
}

function success () {
  if(enabled === true) {
    window.console.info('%cFDN GPT', greenb, ...arguments);
  }
}

function warn () {
  if(enabled === true) {
    window.console.warn('%cFDN GPT', orangeb, ...arguments);
  }
}

function enable () { enabled = true; }
function disable () { enabled = false; }
function isEnabled () { return enabled; }